import * as React from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import { Box, Container, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/product/crystal-green.scss';
import PlayIcon from '../../images/play-icon.svg';

const CrystalGreenPearl = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara-crystal-green-video.jpeg',
    playing: true,
    controls: true,
    width: '100%',
  };

  return (
    <Layout>
      <SEO
        title="Crystal Green Pearl Fertilizer | Root-Activated Fertilizer | Ostara"
        description="Discover Crystal Green Pearl's enhanced-efficiency, Root-Activated fertilizer made from recovered nutrients and proven to enhance yield with better nutrient uptake."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Crystal Green Pearl Fertilizer"
      />
      <main>
        <Box w="100%" p={4} className="crystal-green-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={[
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
              ]}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <StaticImage
                  className="crystal-green-hero-img"
                  placeholder="transparent"
                  src="../../images/crystalpearl1-min.png"
                  alt="Ostara logo"
                />
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <h1>
                  Crystal Green Pearl<sup className="sup-character">®</sup>{' '}
                  Fertilizer
                </h1>
                <p>
                  Enhanced efficiency fertilizer developed using nutrient
                  recovery that puts your crops in control of nutrient uptake
                  with reduced runoff and soil fixation.
                </p>
                <Box className="button-wrappers">
                  <CustomButton
                    variant="crystal"
                    maxW={250}
                    to="/contact"
                    className="button-crystal"
                  >
                    CONTACT US TO ORDER
                  </CustomButton>
                </Box>
              </Stack>
            </Box>

            <Box
              display={['block', 'block', 'block', 'flex']}
              justifyContent="space-between"
            >
              <Flex
                w={['100%', '100%', '100%', '46.5%']}
                marginTop={10}
                direction="column"
                space={6}
              >
                <StaticImage
                  className="crystal-green-hero-img"
                  placeholder="transparent"
                  src="../../images/crystalpearl2-min.png"
                  alt="Ostara logo"
                />
                <Box marginTop={10}>
                  <StaticImage
                    className="crystal-green-hero-img"
                    placeholder="transparent"
                    src="../../images/crystalpearl3-min.jpeg"
                    alt="Ostara logo"
                  />
                </Box>
              </Flex>
              <Stack
                className="crystal-green-box"
                marginTop={10}
                w={['100%', '100%', '100%', '46.5%']}
                spacing={6}
              >
                <h5>
                  About Crystal Green Pearl<sup>®</sup> Fertilizer
                </h5>
                <p>
                  Crystal Green Pearl fertilizer uses Crop-Driven Release™
                  technology to provide phosphorus, along with nitrogen and
                  magnesium (5-28-0 with 10% Mg), when roots need it,
                  benefitting crops with better phosphate control and uptake for
                  higher yields all season long. Crystal Green Pearl is
                  available in SGN 90 and SGN 150.
                </p>
                <h5>Key Nutrients</h5>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/key-nutrients-micrographic2.png"
                  alt="Ostara img"
                  className="ingredients-graph"
                />
                <p>DERIVATION</p>
                <div className="crystal-green-derivation">
                  <h3>MgNH₄PO₄ - 6H₂O</h3>
                  <h4>MAGNESIUM AMMONIUM PHOSPHATE HEXAHYDRATE</h4>
                </div>
              </Stack>
            </Box>

            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <StaticImage
                placeholder="transparent"
                src="../../images/crystalpearl4-min.jpg"
                alt="Ostara logo"
              />
              <Box w="100%">
                <h4>MADE FROM RECOVERED NUTRIENTS</h4>
                <h1>The Most Sustainable Fertilizer</h1>
                <p>
                  Crystal Green Pearl is produced using Ostara’s nutrient
                  recovery technology that reclaims and reuses excess
                  phosphorous and nitrogen from municipal and industrial water
                  supplies. By removing excess phosphorous from wastewater and
                  turning it into high-efficiency fertilizer to reduce nutrient
                  loss to global waterways, Crystal Green Pearl closes the
                  phosphate loop.
                </p>
                <Box marginTop={6}>
                  <Link to="/technology">
                    Learn About Nutrient Recovery
                    <ArrowForwardIcon marginLeft={2} color="#044606" />
                  </Link>
                </Box>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <Box w="100%">
                <h4>HOW CRYSTAL GREEN PEARL WORKS</h4>
                <h1>Gets to the Root of Nutrient Uptake</h1>
                <p>
                  Crystal Green Pearl fertilizer releases phosphate, plus
                  nitrogen and magnesium, in response to organic acids emitted
                  by roots. With ongoing access to phosphate according to crop
                  demand, Crystal Green safely supplies the roots with nutrients
                  needed for optimal plant growth.
                </p>
              </Box>
              <Box w="100%">
                <ReactPlayer className="module-video" {...config} />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="product-body-hero-container">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack marginTop={[0, 100]} maxW="45rem" spacing={6}>
              <h4>BENEFITS</h4>
              <h2>Produce More, Waste Less</h2>
              <p>
                Using Crop Driven Release Technology™, Crystal Green Pearl
                fertilizer optimizes uptake and reduces soil tie-up and nutrient
                runoff for maximum efficiency. By preventing runoff, Crystal
                Green Pearl helps protect local water resources while increasing
                yield. Farmers finally have optimized nutrition for crops that
                increases profitability and production while positively
                impacting the environment at the same time.
              </p>
            </Stack>
          </Container>
          <Container className="key-features" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 3, lg: 3 }} spacing={6}>
              <Link to="/applications/improve-yield">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon1.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Improve
                    <br />
                    Yields
                  </p>
                </Flex>
              </Link>
              <Link to="/applications/soil-tie-up">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon3.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Reduce Soil
                    <br />
                    Tie-Up
                  </p>
                </Flex>
              </Link>
              <Link to="/applications/nutrient-runoff">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon2.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Avoid Nutrient
                    <br />
                    Runoff and Leaching
                  </p>
                </Flex>
              </Link>

              {/* <Link to="/applications/turf-ornaments">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../../images/crystal-green-icon4.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Enhance
                    <br />
                    Soil Health
                  </p>
                </Flex>
              </Link> */}
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="crystal-green-soil-type-container">
          <Container maxW={1400}>
            <h4>SOIL TYPES</h4>
            <h2>Putting the pH in Phosphate Uptake</h2>
            <div className="soil-type-chart">
              <Box className="chart-legends">
                <p className="legend1">Phosphate fixation by Iron (Fe)</p>
                <p className="legend2">
                  Phosphate fixation due to Aluminium (Al)
                </p>
                <p className="legend3">Phosphate fixation by Calcium (Ca)</p>
              </Box>
              <Box className="chart">
                <StaticImage
                  placeholder="transparent"
                  src="../../images/soil-type.svg"
                  alt="Ostara icon"
                  width={700}
                />
              </Box>
            </div>
          </Container>
        </Box>
        {/* <Box w="100%" className="crystal-green-pearl-carousel-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>Research Proven Results</h2>
            <CrystalPearlCarousel />
            <h2>View More Crop Data</h2>
            <div className="crop-data-button-group">
              <Link to="/">CORN</Link>
              <Link to="/">SOYBEANS</Link>
              <Link to="/">HEAT</Link>
              <Link to="/">POTATO</Link>
              <Link to="/">CANOLA</Link>
              <Link to="/">ALFALFA</Link>
              <Link to="/">PEAS</Link>
              <Link to="/">SUGAR BEETS</Link>
            </div>
          </Container>
        </Box> */}
        {/* <Box w="100%" paddingY={4} className="crystal-research-container">
          <StaticImage
            className="crystal-research-detail-img"
            placeholder="transparent"
            src="../../images/crystal-green-pearl-img7.png"
            alt=""
          />
          <Box
            w={['100%', '100%', '60%', '53%']}
            className="crystal-research-detail-wrapper"
          >
            <Stack className="crystal-research-detail" maxW={626}>
              <h4>CASE STUDIES</h4>
              <Link to="/contact">
                Meet Nuria and see her sugar beets crop data after using Crystal
                Green last season.
              </Link>
            </Stack>
          </Box>
        </Box> */}

        <Container className="body-cta-container" maxW={1400}>
          <Box className="body-cta">
            <Link to="/contact">
              Contact us for more information or to order Crystal Green Pearl
            </Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default CrystalGreenPearl;
